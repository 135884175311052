import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import useUpdateTaskDetails from "../../hooks/useUpdateTaskDetails";
import useTaskDetailsList from "../../hooks/useTaskDetailsList";

const TaskEditForm = ({ open, handleClose, taskData }) => {
    console.log(taskData, "TaskEditForm")
  const [formValues, setFormValues] = useState({
    task_id: null,
    task_name: "",
    added_date: null,
    start_date: null,
    release_date: null,
    status: "",
  });

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      task_id: taskData?.task_id,
      task_name: taskData?.task_name,
      added_date: dayjs(taskData?.added_date),
      start_date: dayjs(taskData?.started_date),
      release_date: dayjs(taskData?.released_date),
      status: taskData?.task_status,
    }));
  }, [taskData]);

  console.log(formValues, "formValues")
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);

  const validateTaskForm = () => {
    const newErrors = {};
    let isValid = true;

    // Task name validation
    if (!formValues.task_name.trim()) {
      newErrors.task_name = "Task name is required";
      isValid = false;
    }

    // Status validation
    if (!formValues.status) {
      newErrors.status = "Status is required";
      isValid = false;
    }

    // Added Date validation
    if (!formValues.added_date) {
      newErrors.added_date = "Added date is required";
      isValid = false;
    }

    // Start Date validation
    if (!formValues.start_date) {
      newErrors.start_date = "Start date is required";
      isValid = false;
    } else if (
      formValues.added_date &&
      new Date(formValues.start_date) < new Date(formValues.added_date)
    ) {
      newErrors.start_date = "Start date must be after Added date";
      isValid = false;
    }

    // Release Date validation
    if (!formValues.release_date) {
      newErrors.release_date = "Release date is required";
      isValid = false;
    } else if (
      formValues.start_date &&
      new Date(formValues.release_date) < new Date(formValues.start_date)
    ) {
      newErrors.release_date = "Release date must be after Start date";
      isValid = false;
    }

    // // File validation
    // if (!file) {
    //   newErrors.file = "File is required";
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };

  const updateTaskDetails=useUpdateTaskDetails()
  const getAllTaskDetailsList = useTaskDetailsList("ALL", "ALL");

  const handleSubmit = async() => {
    // if (validateTaskForm()) {
      console.log("Form Submitted", formValues);
      await updateTaskDetails(formValues)
      await getAllTaskDetailsList()
      handleClose();
    // } else {
    //   console.log("Validation Failed");
    // }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px", // exact width
          maxWidth: "90%", // optional
        },
      }}
    >
      <DialogTitle>Update Task Details</DialogTitle>
      <DialogContent>
        <TextField
          label="Task Name"
          fullWidth
          size="small"
          margin="normal"
          value={formValues?.task_name}
          onChange={(e) =>
            setFormValues({ ...formValues, task_name: e.target.value })
          }
          error={!!errors.task_name}
          helperText={errors.task_name}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Added Date"
            value={formValues.added_date}
            onChange={(newValue) =>
              setFormValues({ ...formValues, added_date: newValue })
            }
            slotProps={{
              textField: {
                margin: "normal",
                fullWidth: true,
                size: "small",
                error: !!errors.added_date,
                helperText: errors.added_date,
              },
            }}
          />

          <DatePicker
            label="Start Date"
            value={formValues.start_date}
            onChange={(newValue) =>
              setFormValues({ ...formValues, start_date: newValue })
            }
            slotProps={{
              textField: {
                margin: "normal",
                fullWidth: true,
                size: "small",
                error: !!errors.start_date,
                helperText: errors.start_date,
              },
            }}
          />

          <DatePicker
            label="Release Date"
            value={formValues.release_date}
            onChange={(newValue) =>
              setFormValues({ ...formValues, release_date: newValue })
            }
            slotProps={{
              textField: {
                margin: "normal",
                fullWidth: true,
                size: "small",
                error: !!errors.release_date,
                helperText: errors.release_date,
              },
            }}
          />
        </LocalizationProvider>

        <FormControl
          fullWidth
          margin="normal"
          error={!!errors.status}
          size="small"
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={formValues.status || ""}
            onChange={(e) =>
              setFormValues({ ...formValues, status: e.target.value })
            }
            label="Status"
          >
            <MenuItem value="NEW">NEW</MenuItem>
            <MenuItem value="IN-PROCESS">IN-PROCESS</MenuItem>
            <MenuItem value="COMPLETED">COMPLETED</MenuItem>
            <MenuItem value="RELEASED">RELEASED</MenuItem>
          </Select>
          {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
        </FormControl>

        {/* <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          style={{ marginTop: "16px" }}
        />
        {errors.file && (
          <div style={{ color: "red", fontSize: "0.8rem" }}>{errors.file}</div>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskEditForm;