// MyPopup.js
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import { Stack } from "@mui/system";
import useTaskUpload from "../../actions/TaskUpload/useTaskUpload";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import GenerateTemplate from "./GenerateTemplate";
import useTaskDetailsList from "../../hooks/useTaskDetailsList";
import { Box, CircularProgress } from "@material-ui/core";
// const countries = ['1', '2', '3']; // Replace with your list of countries

const MyPopup = ({ setOpenPopup }) => {
  const fullScreen = useMediaQuery("(max-width:600px)");
  const countries = useSelector((state) => state.country.showContryList);
  const [formValues, setFormValues] = useState({
    task_name: "",
    country_id: "",
    email_id: "",
    ooh_ist: "",
    pinOne: "",
    mobileOne: "",
    pinTwo: "",
    mobileTwo: "",
    pinThree: "",
    mobileThree: "",
    amex_cn: "",
    // wallet: '',
    visa_cn: "",
    master_cn: "",
    discover_cn: "",
    // paypal: ''
  });
  const [file, setFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [errors, setErrors] = useState({
    task_name: false,
    country_id: false,
    email: false,
    file: false,
    ooh: false,
    pinOne: false,
    mobileOne: false,
    pinTwo: false,
    mobileTwo: false,
    pinThree: false,
    mobileThree: false,
    amexCard: false,
    wallet: false,
    visaCard: false,
    discoverCard: false,
    masterCard: false,
    paypal: false,
  });

  // Api Calls
  const getAllTaskDetailsList = useTaskDetailsList("ALL", "ALL");

  const handleClose = () => {
    setOpenPopup(false);
    setSelectedFileName(null);
    setFile(null);
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate taskName
    if (!formValues.task_name.trim()) {
      newErrors.task_name = true;
      isValid = false;
    }

    // // Validate country
    // if (!formValues.country_id) {
    //   newErrors.country_id = true;
    //   isValid = false;
    // }

    // // Validate email
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(formValues.email_id)) {
    //   newErrors.email_id = true;
    //   isValid = false;
    // }
    if (!file) {
      newErrors.file = true;
      isValid = false;
    }
    //   if (!formValues.ooh.trim()) {
    //       newErrors.ooh = true;
    //       isValid = false;
    //     }
    //   if (!formValues.pinOne.trim()) {
    //       newErrors.pinOne = true;
    //       isValid = false;
    //     }
    //   if (!formValues.mobileOne.trim()) {
    //       newErrors.mobileOne = true;
    //       isValid = false;
    //     }
    //   if (!formValues.pinTwo.trim()) {
    //       newErrors.pinTwo = true;
    //       isValid = false;
    //     }
    //   if (!formValues.mobileTwo.trim()) {
    //       newErrors.mobileTwo = true;
    //       isValid = false;
    //     }
    //   if (!formValues.pinThree.trim()) {
    //       newErrors.pinThree = true;
    //       isValid = false;
    //     }
    //   if (!formValues.mobileThree.trim()) {
    //       newErrors.mobileThree = true;
    //       isValid = false;
    //     }
    //   // if (!formValues.wallet.trim()) {
    //   //     newErrors.wallet = true;
    //   //     isValid = false;
    //   //   }
    //   const amexCardRegex = /^\d{16}$/; // Regex for exactly 16 digits
    //   if (!amexCardRegex.test(formValues.amexCard)) {
    //       newErrors.amexCard = true;
    //       isValid = false;
    //     }
    //   const visaCardRegex = /^\d{16}$/; // Regex for exactly 16 digits
    //   if (!visaCardRegex.test(formValues.visaCard)) {
    //       newErrors.visaCard = true;
    //       isValid = false;
    //     }
    //   const discoverCardRegex = /^\d{16}$/; // Regex for exactly 16 digits
    //   if (!discoverCardRegex.test(formValues.discoverCard)) {
    //         newErrors.discoverCard = true;
    //         isValid = false;
    //       }
    //   const masterCardRegex = /^\d{16}$/; // Regex for exactly 16 digits
    //   if (!masterCardRegex.test(formValues.masterCard)) {
    //             newErrors.masterCard = true;
    //             isValid = false;
    //           }
    //   // if (!formValues.paypal.trim()) {
    //   //             newErrors.paypal = true;
    //   //             isValid = false;
    //   //           }
    setErrors(newErrors);
    return isValid;
  };

  const handleFileUpload = (e) => {
    console.log("123");
    const uploadedFile = e.target.files[0];
    console.log(uploadedFile, "rkjdfjlkajf");
    if (uploadedFile) {
      setFile(uploadedFile);
      setSelectedFileName(uploadedFile?.name);
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: false,
      }));
    }
  };

  const handleFileDownload = () => {
    // Handle file download logic
    console.log("File download initiated");
    GenerateTemplate();
  };

  const [isLoading, setIsLoading] = useState(false);
  const taskUpload = useTaskUpload();
  const handleUploadTask = async () => {
    const isValid = validateForm();
    if (isValid) {
      setIsLoading(true);
      const data = new FormData();
      if (file) {
        data.append("file", file);
      }
      // Append form values
      Object.entries(formValues).forEach(([key, value]) => {
        // Skip pin and mobile fields
        if (
          !key.startsWith("pin") &&
          !key.startsWith("mobile") &&
          value !== ""
        ) {
          data.append(key, value);
        }
      });

      // Structure pincode array
      const pincode = [
        formValues.pinOne,
        formValues.pinTwo,
        formValues.pinThree,
      ].filter(Boolean);

      // Structure mobile array
      const mobile = [
        formValues.mobileOne,
        formValues.mobileTwo,
        formValues.mobileThree,
      ].filter(Boolean);

      // Append pincode and mobile arrays to FormData
      data.append("pincode", JSON.stringify(pincode));
      data.append("mobile", JSON.stringify(mobile));

      // Append file
      // console.log('formData:');
      // for (let [key, value] of data.entries()) {
      // console.log(key, value);
      // }

      const response = await taskUpload(data);
      console.log(response);

      if (response.code === 200) {
        setOpenPopup(false);
        setIsLoading(false);

        Swal.fire({
          title: "Success",
          text: "Task Created Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        getAllTaskDetailsList();
        setFormValues({
          task_name: "",
          country_id: "",
          email_id: "",
          ooh_ist: "",
          pinOne: "",
          mobileOne: "",
          pinTwo: "",
          mobileTwo: "",
          pinThree: "",
          mobileThree: "",
          amex_cn: "",
          // wallet: '',
          visa_cn: "",
          master_cn: "",
          discover_cn: "",
          // paypal: ''
        });
        setSelectedFileName(null);
        setFile(null);
      } else if (response.code === 500) {
        setOpenPopup(false);
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        setOpenPopup(false);
        Swal.fire({
          title: "Error",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const handleInputChange = (field, value) => {
    console.log(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: false,
    }));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 1000,
          width: "100%",
        },
      }}
    >
      <DialogTitle sx={{ fontSize: "24px", fontWeight: "700" }}>
        Create New Task
      </DialogTitle>
      <DialogTitle>Task Details</DialogTitle>
      <DialogContent
        sx={{
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          overflowY: "unset",
        }}
      >
        <div
          style={{
            borderLeft: "4px solid #BCE0FF",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="task-name"
              >
                Task Name
              </InputLabel>
              <TextField
                required
                placeholder="Enter Task Name"
                id="task-name"
                variant="outlined"
                size="small"
                value={formValues.task_name}
                onChange={(e) => handleInputChange("task_name", e.target.value)}
                error={errors.task_name}
                helperText={errors.task_name && "**Task Name is required**"}
              />
            </Stack>
            {/* <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "90%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="country"
              >
                Country
              </InputLabel>

              <Select
                id="country"
                variant="outlined"
                // label="Select Country"
                size="small"
                style={{
                  marginLeft: "8px",
                  width: "80%",
                  color: "black !important",
                }}
                value={formValues.country_id}
                onChange={(e) =>
                  handleInputChange("country_id", e.target.value)
                }
                error={errors.country_id && "Select a Country"}
                required
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              >
                {countries?.map((country, index) => (
                  <MenuItem key={index} value={country.country_id}>
                    {country.country_name}
                  </MenuItem>
                ))}
              </Select>
              {errors.country_id && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8rem",
                    marginTop: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  **Select a Country**
                </p>
              )}
            </Stack> */}
          </Stack>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="email"
              >
                Email ID
              </InputLabel>
              <TextField
                placeholder="Enter Email ID"
                id="email"
                variant="outlined"
                size="small"
                value={formValues.email_id}
                onChange={(e) => handleInputChange("email_id", e.target.value)}
                error={errors.email_id}
                helperText={
                  errors.email_id && "**Enter a valid email address**"
                }
                required
              />
            </Stack>
          </div> */}
        </div>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-around",
           
            borderLeft: "4px solid #BCE0FF",
            width: "100%",
          }}
        >
          <InputLabel style={{ marginRight: "8px",  marginLeft: "4rem", }}>URLs Data</InputLabel>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              float: "left",
              justifyContent: "space-evenly",
              width: "65%",
            }}
          >
            <Input
              accept="*/*"
              id="file-upload-input"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <label htmlFor="file-upload-input">
              <Button
                variant="outlined"
                component="span"
                sx={{ textWrap: "nowrap" }}
                startIcon={<UploadFileIcon />}
              >
                {selectedFileName ? selectedFileName : "Upload Excel Sheet"}
              </Button>
              {errors.file && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8rem",
                    marginTop: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  **File is required**
                </p>
              )}
            </label>

            <Button
              variant="outlined"
              sx={{ textWrap: "nowrap", height: "50%" }}
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={handleFileDownload}
            >
              Download Template
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      {/* <DialogTitle>Cheet Sheet</DialogTitle>
      <DialogContent
        sx={{
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          overflowY: "unset",
        }}
      >
        <div
          style={{
            borderLeft: "4px solid #BCE0FF",
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: { xs: "100%", sm: "50%" },
            }}
          >
            <InputLabel htmlFor="ooh" sx={{ width: "100px" }}>
              OOH IST
            </InputLabel>
            <TextField
              placeholder="Enter IST"
              id="ooh"
              variant="outlined"
              size="small"
              value={formValues.ooh_ist}
              onChange={(e) => handleInputChange("ooh_ist", e.target.value)}
              error={errors.ooh}
              helperText={errors.ooh && "OOH is required"}
              required
            />
          </Stack>
        </div>
        <div
          style={{
            borderLeft: "4px solid #BCE0FF",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="mobile-one"
              >
                Mob 1
              </InputLabel>
              <TextField
                placeholder="Enter Mobile Numer"
                id="country"
                variant="outlined"
                size="small"
                value={formValues.mobileOne}
                onChange={(e) => handleInputChange("mobileOne", e.target.value)}
                error={errors.mobileOne}
                helperText={errors.mobileOne && "Mobile No. is required"}
                required
              />
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="mobile-two"
              >
                Mob 2
              </InputLabel>
              <TextField
                placeholder="Enter Mobile Numer"
                id="country"
                variant="outlined"
                size="small"
                value={formValues.mobileTwo}
                onChange={(e) => handleInputChange("mobileTwo", e.target.value)}
                error={errors.mobileTwo}
                helperText={errors.mobileTwo && "Mobile No. is required"}
                required
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="mobile-three"
              >
                Mob 3
              </InputLabel>
              <TextField
                placeholder="Enter Mobile Numer"
                id="country"
                variant="outlined"
                size="small"
                value={formValues.mobileThree}
                onChange={(e) =>
                  handleInputChange("mobileThree", e.target.value)
                }
                error={errors.mobileThree}
                helperText={errors.mobileThree && "Mobile No. is required"}
                required
              />
            </Stack>
          </Stack>
        </div>
        <div
          style={{
            borderLeft: "4px solid #BCE0FF",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="amex-card"
              >
                AMEX
              </InputLabel>
              <TextField
                placeholder="Enter Card Number"
                id="amex_cn"
                variant="outlined"
                size="small"
                value={formValues.amex_cn}
                onChange={(e) => handleInputChange("amex_cn", e.target.value)}
                error={errors.amexCard}
                helperText={errors.amexCard && "Card number is required"}
                required
              />
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="master-card"
              >
                Master Card
              </InputLabel>
              <TextField
                placeholder="Enter Card Number"
                id="master_cn"
                variant="outlined"
                size="small"
                value={formValues.master_cn}
                onChange={(e) => handleInputChange("master_cn", e.target.value)}
                error={errors.masterCard}
                helperText={errors.masterCard && "Card number is required"}
                required
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="visa-card"
              >
                Visa
              </InputLabel>
              <TextField
                placeholder="Enter Card Number"
                id="visa_cn"
                variant="outlined"
                size="small"
                value={formValues.visa_cn}
                onChange={(e) => handleInputChange("visa_cn", e.target.value)}
                error={errors.visaCard}
                helperText={errors.visaCard && "Card number is required"}
                required
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <InputLabel
                sx={{ width: "100px", fontSize: "16px" }}
                htmlFor="discover-card"
              >
                Discover
              </InputLabel>
              <TextField
                placeholder="Enter Card Number"
                id="discover_cn"
                variant="outlined"
                size="small"
                value={formValues.discover_cn}
                onChange={(e) =>
                  handleInputChange("discover_cn", e.target.value)
                }
                error={errors.discoverCard}
                helperText={errors.discoverCard && "Card number is required"}
                required
              />
            </Stack>
          </Stack>
        </div>
      </DialogContent> */}
      <DialogActions sx={{ paddingBottom: "20px", paddingRight: "8%" }}>
        <Button
          sx={{ boxShadow: "none", borderRadius: "0px" }}
          variant="outlined"
          onClick={handleClose}
        >
          Discard
        </Button>
        {isLoading ? (
          <div>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <CircularProgress />
            </Box>
          </div>
        ) : (
          <Button
            sx={{ boxShadow: "none", borderRadius: "0px" }}
            variant="contained"
            onClick={handleUploadTask}
          >
            Upload Task
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MyPopup;
