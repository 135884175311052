import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useUpdateAssignTime = () => {
  //Redux
  const Axios = useAxiosPrivate();
  const updateAssignTime = async (param) => {
    const response = await Axios.post("/url-assign-time", param);
    const data = await response.data;
    // if (data.code === 200) {
    //   toast.success(data.message, {
    //     position: "top-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {
    //   toast.error(data.message, {
    //     position: "top-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  return updateAssignTime;
};

export default useUpdateAssignTime;
