import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useExecutorDashboardReport from "../../hooks/useExecutorDashboardReport";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TailSpin } from 'react-loader-spinner'

const theme = createTheme({
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        toolbarBtn: {
          color: "#006FCF",
        },
      },
    },
  },
});

const ExecutorDashboardTable = () => {
  const navigate=useNavigate()
  const currentDate = dayjs();
  const [loader,setLoader]=useState(false)
  const date = currentDate.subtract(6, 'day');
  const [fromDate,setFromDate]=useState(date)
  const [toDate,setToDate]=useState(currentDate)
  const [rowData, setRowData] = useState(null);
  const getExecutorDashboardData = useExecutorDashboardReport(setLoader);
  const executorReport = useSelector(
    (store) => store.task.executorDashboardReport
  );

  const getExecutorReport = async () => {
    let taskNames;
    const executorReport = await getExecutorDashboardData(date,currentDate);
    if(executorReport){
      taskNames = Object.keys(executorReport?.daily_data?.[0]).filter(
        (key) => key !== "date"
      );
  
      const rows = taskNames?.map((task) => {
        return task;
      });
  
      setRowData(rows)
    }
  };

  useEffect(()=>{
    getExecutorReport()
  },[])

  const formatData = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });

    const formattedDate = `${day} ${month}`;
    return formattedDate;
  };

  const handleBack=()=>{
    navigate("/")
  }

  const handleDateChange = async (date, name) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
    let executorReport;
    let taskNames;
    if (name === "from") {
      setFromDate(date);
      executorReport = await getExecutorDashboardData(
        formattedDate,
        dayjs(toDate).format("YYYY-MM-DD")
      );
      if (executorReport) {
        taskNames = Object.keys(executorReport?.daily_data?.[0]).filter(
          (key) => key !== "date"
        );
    
        const rows = taskNames?.map((task) => {
          return task;
        });
    
        setRowData(rows)
      }
    } else {
      setToDate(date);
      executorReport = await getExecutorDashboardData(
        dayjs(fromDate).format("YYYY-MM-DD"),
        formattedDate
      );
      if (executorReport) {
        taskNames = Object.keys(executorReport?.daily_data?.[0]).filter(
          (key) => key !== "date"
        );
    
        const rows = taskNames?.map((task) => {
          return task;
        });
    
        setRowData(rows)
      }
    }
  };



  return loader ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  ) : (
    <Box
      sx={{
        marginTop: "20px",
        marginLeft: { xs: "16px", md: "32px" },
        marginRight: { xs: "16px", md: "32px" },
        paddingBottom: "32px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <KeyboardBackspaceIcon
            onClick={handleBack}
            sx={{ cursor: "pointer", fontSize: "30px" }}
          />
          <Typography
            style={{
              marginLeft: "10px",
              fontSize: "24px",
              fontWeight: "400",
              lineHeight: "36px",
            }}
          >
            Executor Report
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.8rem" }}>
          <Box sx={{ display: "flex", alignSelf: "center", gap: "10px" }}>
            {" "}
            <Typography sx={{ alignItems: "center", alignSelf: "center" }}>
              {" "}
              From:{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ maxWidth: "155px" }}
                slotProps={{ textField: { size: "small" } }}
                value={fromDate}
                onChange={(e) => handleDateChange(e, "from")}
                format="DD MMM YYYY"
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", alignSelf: "center", gap: "10px" }}>
            {" "}
            <Typography sx={{ alignItems: "center", alignSelf: "center" }}>
              {" "}
              To:{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <DatePicker
                  sx={{ maxWidth: "150px" }}
                  slotProps={{ textField: { size: "small" } }}
                  value={toDate}
                  onChange={(e) => handleDateChange(e, "to")}
                  format="DD MMM YYYY"
                />
              </ThemeProvider>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>

      <TableContainer
        sx={{ maxHeight: "600px", marginTop: "15px" }}
        component={Paper}
      >
        <Table>
          <TableHead
            sx={{
              color: "#9D9D9D",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              position: "sticky",
              top: 0,
              zIndex: 10,
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  // backgroundColor: "#FFF",
                  backgroundColor: "#F0FAFB",
                  padding: "8px !important",
                  zIndex: 11,
                }}
              >
                Date
              </TableCell>
              {rowData?.map((row, index) =>
                row === "Total" ? (
                  <TableCell
                    key={index}
                    sx={{
                      position: "sticky",
                      right: 0,
                      // backgroundColor: "#FFF",
                      padding: "8px !important",
                      backgroundColor: "#F1F5F7",
                      zIndex: 11,
                    }}
                  >
                    {row}
                  </TableCell>
                ) : (
                  <TableCell
                    key={index}
                    sx={{
                      backgroundColor: "#E6E9EB",
                      padding: "8px !important",
                    }}
                  >
                    {row}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {executorReport?.daily_data?.map((data, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    // backgroundColor: "#FFF",
                    padding: "8px !important",
                    backgroundColor: "#F0FAFB",
                    zIndex: 11,
                  }}
                >
                  {formatData(data?.date)}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.s1_s2}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.s1_dwh}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.s1_dex}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.s1_qa}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.s2_dwh}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.s2_dex}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.s2_qa}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.dwh_dex}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.dwh_qa}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.dex_dwh}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.dex_qa}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.dup_qa}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.qa_com}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.com_rel}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.qa_qa}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.com_com}
                </TableCell>
                <TableCell sx={{ padding: "8px !important" }}>
                  {data?.rel_rel}
                </TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    right: 0,
                    // backgroundColor: "#FFF",
                    padding: "8px !important",
                    backgroundColor: "#F1F5F7",
                    zIndex: 11,
                  }}
                >
                  {data?.Total}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExecutorDashboardTable;












