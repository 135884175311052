import { useEffect } from "react";
import { getMarketList, getTaskDetailsList } from "../slice/taskSlice";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
const useMarketList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllMarketList = async (countryCode) => {
    let param={
      country_code: countryCode
    }
    const response = await Axios.post("/market-list",param);
    const data = await response.data;
    if(data.code===200){
      dispatch(getMarketList(data.market_list))
      return data;
    }
  };

  useEffect(()=>{
    getAllMarketList()
  },[])

  return getAllMarketList
};

export default useMarketList;
