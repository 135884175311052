import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  CircularProgress,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import carddata from "./CardData.json";
import taskTableData from "./taskTableData.json";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Divider from "@mui/material/Divider";
import ImageViewer from "./ImageViewer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAccessStatusList from "../../hooks/useAccessStatusList ";
import useURLDetails from "../../hooks/useURLDetails";
import Pagination from "./Pagination";
import axios from "axios";
import useURLDetailsStatus from "../../hooks/useURLDetailsStatus";
import useTaskDetailsList from "../../hooks/useTaskDetailsList";
import Modal from "@mui/material/Modal";
import { useLocation } from "react-router-dom";
import useTotalQAStatusCount from "../../hooks/useTotalQAStatusCount";
import PopUpWithLoader from "./PopUpWithLoader";
import useQAAccessStatusList from "../../hooks/useQAAccessStatusList";
import GenerateCheetSheet from "./GenerateCheetSheet";
import ExportUrl from "./ExportUrl";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import useTotalLevel1QAStatusCount from "../../hooks/useTotalLevel1QAStatusCount";
import useLevel1Check from "../../hooks/useLevel1Check";
import useLevel1Start from "../../hooks/useLevel1Start";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLevel1Stop from "../../hooks/useLevel1Stop";
import useMoveTofCOM from "../../hooks/useMoveTofCOM";
import useMoveTofREL from "../../hooks/useMoveTofREL";
import useReleaseTaskStatus from "../../hooks/useReleaseTaskStatus";
import useTotalLevel1QAAltStatusCount from "../../hooks/useTotalLevel1QAAltStatusCount";
import useExcelSheetData from "../../hooks/useExcelSheetData";
import useRELCount from "../../hooks/useRELCount";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import {
  getCurrentSelecteddId,
  getFilterCount,
  getFilterSelectedRemark,
  getSelectedCardData,
  getSelectedRemarkStatus,
} from "../../slice/QASlice";
import useDuplicateList from "../../hooks/useDuplicateList";
import useDuplicateDetails from "../../hooks/useDuplicateDetails";
import useUpdateDuplicate from "../../hooks/useUpdateDuplicate";
import { display } from "@mui/system";
import useResetURLStatus from "../../hooks/useResetURLStatus";

const ITEMS_PER_PAGE = 10;

const TaskDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const rowData = location.state?.rowData;
  const taskId = localStorage.getItem("task_id");
  const [customerCardSelected, setCustomerCardSelected] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [allRowsExpanded, setAllRowsExpanded] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [selectedCardData, setSelectedCardData] = useState({});
  const [dId, setdId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openTool, setOpenTool] = useState(false);
  const [buttonName, setButtonName] = useState("start");
  const [comments, setComments] = useState({});
  const [isEditRemark, setIsEditRemark] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const userData= useSelector((state)=>state.auth.userData)
  const authorizedEmails = [
    "amxuser@pops.echnotek.com",
    "harishma.k@pops.echnotek.com",
    "pranjali.k@pops.echnotek.com",
    "satyajit@pops.echnotek.com",
    "saket@pops.echnotek.com",
    "dwight@pops.echnotek.com",
    "claire.kennedy@aexp.com",
    "tanya.smith@aexp.com"
  ];

  //Api Calls

  useRELCount();
  useDuplicateList();
  useExcelSheetData();
  useURLDetailsStatus(selectedCardData);
  useQAAccessStatusList(selectedCardData);
  const moveTofCOM = useMoveTofCOM();
  const moveTofREL = useMoveTofREL();
  const getLevel1Stop = useLevel1Stop();
  const getLevel1Check = useLevel1Check();
  const getLevel1Start = useLevel1Start();
  const updateDuplicate = useUpdateDuplicate();
  const getReleaseTaskStatus = useReleaseTaskStatus();
  const getAllDuplicateDetails = useDuplicateDetails();
  const getAllTaskDetailsList = useTaskDetailsList("ALL");
  const getAllTotalQAStatusCount = useTotalQAStatusCount();
  const getAllTotalLevel1QAStatusCount = useTotalLevel1QAStatusCount();
  const { isLoading, SetIsLoading, getAllURLDetails } = useURLDetails();
  const getAllTotalLevel1QAAltStatusCount = useTotalLevel1QAAltStatusCount();
  const { getAllAccessStatusList, isAccessLoading, setIsAccessLoading } =
    useAccessStatusList();
  const handleMoveReleasedClick = (taskId) => {
    getReleaseTaskStatus(taskId);
    getAllTaskDetailsList();
  };

  //Redux
  const statusList = useSelector((store) => store.status.accessStatusList);
  const allDuplicateList = useSelector((state) => state.qa.duplicateList);
  const currentStatusId = useSelector((store) => store.qa.selectedCardData);
  const userRole = useSelector((state) => state?.auth?.userData?.role_code);
  const urlDetails = useSelector((state) => state?.task?.URLDetails);
  const userId = useSelector((state) => state.auth.userData.user_id);
  const totalQACount = useSelector((state) => state.qa.totalQAStatusCount);
  const relCount = useSelector((state) => state.qa.relCount);
  const current_task_id = localStorage.getItem("task_id");
  const totalLevel1QACount = useSelector(
    (state) => state.qa.totalLevel1QAStatusCount
  );
  const totalLevel1QAAltCount = useSelector(
    (state) => state.qa.totalLevel1QAAltStatusCount
  );
  const checkData = useSelector((state) => state.task.level1CheckData);

  const handleViewScreenshot = (imageUrl) => {
    console.log(imageUrl);
    if (
      imageUrl?.length == 0 ||
      !imageUrl ||
      imageUrl === undefined ||
      imageUrl?.[0] === null
    ) {
      setOpen(true);
    } else {
      setSelectedImageUrl(imageUrl);
      setOpenImageDialog(true);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  useEffect(() => {
    getAllAccessStatusList();
    const intervalId = setInterval(() => {
      getAllAccessStatusList();
    }, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      console.log("Component is unmounting", "accessStatusList777");
    };
  }, []);

  useEffect(() => {
    getAllTotalLevel1QAAltStatusCount();
    const intervalId = setInterval(() => {
      getAllTotalLevel1QAAltStatusCount();
    }, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      console.log("Component is unmounting", "accessStatusList777");
    };
  }, []);

  useEffect(() => {
    getAllTotalLevel1QAStatusCount();
    const intervalId = setInterval(() => {
      getAllTotalLevel1QAStatusCount();
    }, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      console.log("Component is unmounting", "accessStatusList777");
    };
  }, []);

  useEffect(() => {
    getAllTotalQAStatusCount();
    const intervalId = setInterval(() => {
      getAllTotalQAStatusCount();
    }, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      console.log("Component is unmounting", "accessStatusList777");
    };
  }, []);

  useEffect(() => {
    dispatch(getFilterSelectedRemark(null));
    dispatch(getFilterCount(0));
  }, []);

  useEffect(() => {
    // Initialize comments state with rowData comments
    const initialComments = {};
    allDuplicateList?.forEach((rowData) => {
      if (rowData?.d_id && rowData?.comment) {
        initialComments[rowData.d_id] = rowData.comment;
      }
    });
    setComments(initialComments);
  }, [allDuplicateList]);

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardClick = (index, statusId, statusCount) => {
    dispatch(getSelectedCardData(statusId));
    setSelectedCardIndex(index);
    let statusName = "";
    // let taskId = 0;
    for (let i = 0; i < statusList?.length; i++) {
      if (statusId === statusList[i].status_id) {
        statusName = statusList[i]?.status_name;
      }
    }
    setSelectedCardData((prev) => ({
      ...prev,
      status_id: statusId,
      status_name: statusName,
      task_id: taskId,
      status_count: statusCount,
    }));
  };

  const handleButtonClick = async () => {
    dispatch(getSelectedRemarkStatus(false));
    if (selectedCardIndex !== null) {
      const selectedCard = carddata[selectedCardIndex];

      if (selectedCard?.type === "Select") {
        if (selectedCard.flag === 1) {
          navigate("/qal1");
        } else if (
          selectedCard.flag === 2 &&
          totalQACount?.[0]?.total_qa_status > 0
        ) {
          navigate("/qal2");
        } else if (selectedCard.flag === 3) {
          navigate("/qacom");
        } else if (selectedCard.flag === 4) {
          navigate("/qarel");
        } else if (selectedCard.flag === 6) {
          navigate("/l2S2");
        } else if (selectedCard.flag === 5) {
          navigate("/l2S1");
        } else if (selectedCard.flag === 7) {
          navigate("/fNW");
        } else if (selectedCard.flag === 8) {
          navigate("/fDWH");
        }
      }

      // if (selectedCard?.type === "Start" && selectedCard?.flag === 3) {
      //   getLevel1Start();
      //   getLevel1Check();
      //   // getLevel1Check();
      // }

      if (selectedCard?.type === "Start" && selectedCardData.status_count > 0) {
        setOpenTool(true);
        try {
          const response = await axios.get("http://localhost:5000/invoke-L2", {
            params: {
              task_id: current_task_id,
              status_id: selectedCardData?.status_id,
              user_id: userId,
            },
            mode: "cors", // Allow cross-origin requests
            // headers: {
            //   "Content-Type": "application/json",
            // },
          });

          console.log(response, "qwertyuiop");
          if (response.status === 200) {
            setOpenTool(false);
          }

          console.log(response, "qwertyuiop");
          console.log(response.status, "qwertyuiop");
        } catch (error) {
          console.error("Error invoking Python script:", error);
        }
      }
    }
  };

  const handleStopClick = async (e) => {
    console.log(e.target.name);
    setButtonName(e.target.name);
    if (e.target.name === "stop") {
      await getLevel1Stop();
      await getLevel1Check();
    } else if (e.target.name === "start") {
      await getLevel1Start();
      await getLevel1Check();
    }
  };

  const handleRemarkEdit = (e, d_id) => {
    setComments((prevComments) => ({
      ...prevComments,
      [d_id]: e.target.value,
    }));
  };

  const handleMoveClick = (value) => {
    if (value === "f-L2-S4") {
      moveTofCOM();
      getAllAccessStatusList();
    } else if (value === "f-COM") {
      moveTofREL();
      getAllAccessStatusList();
    }
  };

  const toggleRow = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };
  const toggleAllRows = () => {
    const newAllRowsExpanded = !allRowsExpanded;
    setAllRowsExpanded(newAllRowsExpanded);
    setExpandedRows(
      newAllRowsExpanded ? Array(taskTableData.length).fill(true) : []
    );
  };

  function getStatusIcon(value) {
    return value ? (
      <CheckIcon style={{ color: "#0EA101" }} />
    ) : (
      <HorizontalRuleIcon style={{ color: "#FF0000" }} />
    );
  }

  const handleRelCardClick = () => {
    setCustomerCardSelected(!customerCardSelected);
  };

  const handleStart = async (d_id) => {
    await getAllDuplicateDetails(d_id);
    dispatch(getCurrentSelecteddId(d_id));
    setdId(d_id);
  };

  useEffect(() => {
    if (dId !== null) {
      navigate("/dupPage");
    }
  }, [dId]);

  const handleEdit = (d_id) => {
    setIsEditRemark((prevEditStates) => ({
      ...prevEditStates,
      [d_id]: true,
    }));
  };

  const handleSave = async (d_id) => {
    setIsEditRemark((prevEditStates) => ({
      ...prevEditStates,
      [d_id]: true,
    }));
    await updateDuplicate(d_id, comments?.[d_id]);
    await getAllDuplicateDetails(d_id);
    setIsEditRemark((prevEditStates) => ({
      ...prevEditStates,
      [d_id]: false,
    }));
  };

  const handleCancel = (d_id) => {
    setIsEditRemark((prevEditStates) => ({
      ...prevEditStates,
      [d_id]: false,
    }));
  };

  //  Pagination Logic

  // Calculate the start and end index based on the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const [filteredData, setFilteredData] = useState(urlDetails);
  // Filter the data to show only the items for the current page
  const displayedData = filteredData?.slice(startIndex, endIndex);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredData(urlDetails);
    } else {
      const getData = setTimeout(() => {
        setFilteredData(
          urlDetails?.filter(
            (row) =>
              (row?.url_id?.toString() ?? "")?.includes(searchTerm) ||
              (row?.sno?.toString() ?? "")?.includes(searchTerm) ||
              (row?.url ?? "")?.includes(searchTerm) ||
              (row?.segment ?? "")?.includes(searchTerm)
          )
        );
        setCurrentPage(1);
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [searchTerm, urlDetails]);

  const resetURLStatus = useResetURLStatus()
  const resetURL = async () => {
    let param = {
      task_id: localStorage.getItem("task_id"),
    };
    await resetURLStatus(param);
  };

  return (
    <div>
      {openTool && (
        <PopUpWithLoader open={openTool} onClose={() => setOpenTool(false)} />
      )}
      <ToastContainer />
      <Container
        sx={{
          marginTop: "24px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          gap: "20px",
          alignItems: { xs: "center", sm: "start" },
          paddingBottom: "32px",
        }}
        maxWidth="2xl"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: "24px", fontWeight: "400", lineHeight: "36px" }}
          >
            {rowData?.task_name}
            <span
              style={{
                marginLeft: "20px",
                padding: "8px 18px",
                backgroundColor: "#FFEFE1",
                color: "#FF9131",
                borderRadius: "32px",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {rowData?.task_status}
            </span>
            {(userRole === "admin" && !authorizedEmails.includes(userData?.email)) && (
              <Button
                disableElevation
                variant="contained"
                onClick={() => handleMoveReleasedClick(rowData?.task_id)}
                name="moveToReleased"
                sx={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}
              >
                Move to Released State
              </Button>
            )}
          </Typography>
          <Typography
            sx={{
              marginTop: "12px",
              color: "#000",
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "24px",
            }}
          >
            Created :{" "}
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {" "}
              {rowData?.added_date}
            </span>{" "}
            | Started :{" "}
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {rowData?.started_date}
            </span>
          </Typography>
          <Typography
            sx={{
              // marginTop: "12px",
              color: "#000",
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "24px",
              padding: "8px 16px",
              background: "#F9F9F9",
              width: "fit-content",
            }}
          >
            Total URLs -{" "}
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {rowData?.all_urls_count}
            </span>
          </Typography>
          <Typography
            sx={{
              // marginTop: "12px",
              color: "#000",
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "24px",
              padding: "8px 16px",
              background: "#F9F9F9",
              width: "fit-content",
            }}
          >
            Market URLs ({localStorage.getItem("selectedMarket")}) -{" "}
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {rowData?.total_urls}
            </span>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "start" }}>
          {/* <Button
            sx={{ borderRadius: "0px", textTransform: "none" }}
            startIcon={<FileDownloadOutlinedIcon />}
            variant="outlined"
            onClick={handleCheetSheet}
          >
            Cheet Sheet
          </Button> */}
          {/* <GenerateCheetSheet /> */}
          <Button
            sx={{
              textTransform: "none",
              borderRadius: "0",
              background: "#f04d5d",
              "&:hover": { background: "#f04d5d" },
              visibility:
                (userData?.role_code === "executor" ||
                  userData?.role_code === "admin") &&
                !authorizedEmails.includes(userData?.email)
                  ? "visible"
                  : "hidden",
            }}
            disableElevation
            variant="contained"
            onClick={resetURL}
            startIcon={<RestartAltIcon />}
          >
            Reset
          </Button>
          <ExportUrl />
        </Box>
      </Container>

      {isLoading === false && (
        <Stack sx={{ backgroundColor: "#F8F8F8" }}>
          {/* <PopDistrubution /> */}

          {/* <h3
            style={{
              marginLeft: "32px",
              marginBottom: "10px",
              fonSize: "16px",
              fontWeight: "400",
              lineHeight: "36px",
            }}
          >
            List of URLs
          </h3> */}

          {userRole === "customer" && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",

                gap: "1rem",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                marginTop: "1.5rem",
              }}
            >
              <Card
                // key={status?.status_id}
                sx={{
                  border: "0.2px solid #585858",
                  mb: { xs: 2, lg: 0 },
                  borderRadius: "0px",
                  position: "relative",
                  overflow: "visible",
                  marginBottom: "10px !important",
                  width: "125px",
                  height: "50px",
                  cursor: "pointer",
                }}
                elevation={0}
                onClick={() => handleRelCardClick()}
              >
                {/* {selectedCardIndex === index && ( */}
                {/* {customerCardSelected && ( */}
                <CheckCircleIcon
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    borderRadius: "50%",

                    color: "#329519",
                  }}
                />
                {/* )} */}
                {/* )} */}
                <CardContent
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    padding: "10px 32px",

                    alignItems: "center",
                    gap: "4px",
                    "&:last-child": {
                      paddingBottom: "10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#A2A2A2",
                      textWrap: "nowrap",
                    }}
                    variant="p"
                  >
                    f-REL
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "700" }}
                    variant="p"
                  >
                    {relCount}
                  </Typography>
                </CardContent>
              </Card>
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="/relPage"
              >
                <Button
                  disableElevation
                  // disabled={!customerCardSelected}
                  variant="contained"
                  // onClick={(e) => handleCheckClick(e)}
                  name="stop"
                  sx={{
                    width: "120px",
                    height: "3rem",
                    textTransform: "none",
                    borderRadius: "0",
                    color: "white",
                    background: "primary",
                    "&:hover": {
                      background: "primary",
                    },
                  }}
                >
                  Check
                </Button>
              </a>
            </Stack>
          )}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              // gap: "0.5rem",
              width: "96%",
              justifyContent: "space-between",
              margin: "0px 2%",
              marginTop: "1rem",
              alignItems: "center",
            }}
          >
            <>
              {userRole !== "customer" &&
                statusList &&
                statusList?.map((status, index) => (
                  <Card
                    key={status?.status_id}
                    sx={{
                      border: "0.2px solid #585858",
                      mb: { xs: 2, lg: 0 },
                      borderRadius: "0px",
                      position: "relative",
                      overflow: "visible",
                      marginBottom: "10px !important",
                      width: "80px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    elevation={0}
                    onClick={() =>
                      handleCardClick(
                        index,
                        status?.status_id,
                        status?.status_count
                      )
                    }
                  >
                    {selectedCardIndex === index && (
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          borderRadius: "50%",

                          color: "#329519",
                        }}
                      />
                    )}
                    <CardContent
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        padding: "10px 32px",

                        alignItems: "center",
                        gap: "4px",
                        "&:last-child": {
                          paddingBottom: "10px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#A2A2A2",
                          textWrap: "nowrap",
                        }}
                        variant="p"
                      >
                        {status?.status_name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "700" }}
                        variant="p"
                      >
                        {status?.status_name === "f-L2-QA"
                          ? totalQACount?.[0]?.total_qa_status
                          : status?.status_name === "f-L1-QA-F"
                          ? parseInt(totalLevel1QACount?.[0]?.total_qa_status) +
                              parseInt(
                                totalLevel1QAAltCount?.[0]?.qal1altcount
                              ) ?? 0
                          : status?.status_count}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}

              {statusList[selectedCardIndex]?.access && (
                <>
                  {statusList[selectedCardIndex].status_id === 2 &&
                  checkData?.level_one_status === "running" ? (
                    <Button
                      disableElevation
                      variant="contained"
                      onClick={(e) => handleStopClick(e)}
                      name="stop"
                      sx={
                        userRole === "customer"
                          ? { display: "none" }
                          : {
                              width: "120px",
                              textTransform: "none",
                              borderRadius: "0",
                              color: "white",
                              background: "red",
                              "&:hover": {
                                background: "red",
                              },
                            }
                      }
                    >
                      Stop
                    </Button>
                  ) : statusList[selectedCardIndex].status_id === 2 &&
                    checkData?.level_one_status === "stopped" ? (
                    <Button
                      disableElevation
                      variant="contained"
                      onClick={(e) => handleStopClick(e)}
                      name="start"
                      sx={
                        userRole === "customer"
                          ? { display: "none" }
                          : {
                              width: "120px",
                              textTransform: "none",
                              borderRadius: "0",
                            }
                      }
                    >
                      Start
                    </Button>
                  ) : statusList[selectedCardIndex].status_name === "f-L2-S4" ||
                    statusList[selectedCardIndex].status_name === "f-COM" ? (
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirecton: "column",
                        gap: "0.5rem",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        disableElevation
                        variant="contained"
                        onClick={() =>
                          handleMoveClick(
                            statusList[selectedCardIndex]?.status_name
                          )
                        }
                        sx={
                          userRole !== "admin"
                            ? { display: "none" }
                            : {
                                width: "140px",
                                textTransform: "none",
                                borderRadius: "0",
                              }
                        }
                      >
                        {statusList[selectedCardIndex]?.status_name === "f-COM"
                          ? "Move to f-REL"
                          : "Move to f-COM"}
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        onClick={handleButtonClick}
                        sx={
                          userRole === "customer"
                            ? { display: "none" }
                            : {
                                width: "120px",
                                textTransform: "none",
                                borderRadius: "0",
                              }
                        }
                      >
                        {selectedCardIndex !== null &&
                        carddata[selectedCardIndex]
                          ? `${carddata[selectedCardIndex].type}`
                          : "Select"}
                      </Button>
                    </Stack>
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      onClick={handleButtonClick}
                      sx={
                        userRole === "customer"
                          ? { display: "none" }
                          : {
                              width: "120px",
                              textTransform: "none",
                              borderRadius: "0",
                            }
                      }
                    >
                      {selectedCardIndex !== null && carddata[selectedCardIndex]
                        ? `${carddata[selectedCardIndex].type}`
                        : "Select"}
                    </Button>
                  )}
                </>
              )}
              {!statusList[selectedCardIndex]?.access && (
                <Button
                  disabled={true}
                  disableElevation
                  variant="contained"
                  onClick={handleButtonClick}
                  sx={
                    userRole === "customer"
                      ? { display: "none" }
                      : {
                          width: "120px",
                          textTransform: "none",
                          borderRadius: "0",
                        }
                  }
                >
                  {selectedCardIndex !== null && carddata[selectedCardIndex]
                    ? `${carddata[selectedCardIndex].type}`
                    : "Select"}
                </Button>
              )}
            </>
          </div>
        </Stack>
      )}

      {isLoading ? (
        <CircularProgress
          sx={{
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : currentStatusId === 25 ? (
        <Stack
          sx={{
            backgroundColor: "#F8F8F8",
            paddingTop: "10px",
            paddingLeft: { xs: "16px", md: "32px" },
            paddingRight: { xs: "16px", md: "32px" },
            paddingBottom: "32px",
          }}
        >
          <TableContainer
            style={{ maxHeight: "800px" }}
            elevation={0}
            component={Paper}
          >
            <Table sx={{ border: "1px solid #E8E8E8" }}>
              <TableHead
                sx={{
                  color: "#9D9D9D",
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#FFF", // Set background color as needed
                  zIndex: 1,
                }}
              >
                {/* Second row for individual column headings */}
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    URL
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Total URLs
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Pending
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Remark
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDuplicateList?.map((rowData, index) => {
                  const descendingIndex = allDuplicateList?.length - index;
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{ fontSize: "16px", fontWeight: "400" }}
                        key={index}
                      >
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            textAlign: "center",
                          }}
                        >
                          {descendingIndex}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            textAlign: "center",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            maxWidth: "150px",
                            overflow: "hidden",
                          }}
                          title={rowData?.url}
                        >
                          {rowData?.url}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            textAlign: "center",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                          title={rowData?.total_url ?? "N/A"}
                        >
                          {rowData?.total_url ?? "N/A"}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            textAlign: "center",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                          title={rowData?.pending ?? "N/A"}
                        >
                          {rowData?.pending ?? "N/A"}
                        </TableCell>

                        {isEditRemark[rowData?.d_id] ? (
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "14px",
                            }}
                          >
                            <input
                              value={comments?.[rowData?.d_id] || ""}
                              style={{ height: "2rem", width: "12rem" }}
                              onChange={(e) =>
                                handleRemarkEdit(e, rowData?.d_id)
                              }
                            />
                          </Stack>
                        ) : (
                          <TableCell
                            sx={{
                              fontSize: "16px",
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            {comments?.[rowData?.d_id] ?? "N/A"}
                          </TableCell>
                        )}

                        <TableCell
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            textAlign: "center",
                          }}
                        >
                          {!isEditRemark?.[rowData?.d_id] ? (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.5rem",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                disableElevation
                                variant="contained"
                                onClick={() => handleStart(rowData?.d_id)}
                                name="start"
                                sx={
                                  userRole === "customer"
                                    ? { display: "none" }
                                    : {
                                        width: "60px",
                                        textTransform: "none",
                                        borderRadius: "0",
                                      }
                                }
                              >
                                Start
                              </Button>
                              <Button
                                disableElevation
                                variant="contained"
                                onClick={() => handleEdit(rowData?.d_id)}
                                name="start"
                                sx={
                                  userRole === "customer"
                                    ? { display: "none" }
                                    : {
                                        width: "60px",
                                        textTransform: "none",
                                        borderRadius: "0",
                                        background: "#a88132",
                                        "&:hover": {
                                          background: "#a88132",
                                        },
                                      }
                                }
                              >
                                Edit
                              </Button>
                            </Stack>
                          ) : (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.5rem",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                disableElevation
                                variant="contained"
                                onClick={() => handleSave(rowData?.d_id)}
                                name="start"
                                sx={
                                  userRole === "customer"
                                    ? { display: "none" }
                                    : {
                                        width: "60px",
                                        textTransform: "none",
                                        borderRadius: "0",
                                        background: "#4d7a3c",
                                        "&:hover": {
                                          background: "#4d7a3c",
                                        },
                                      }
                                }
                              >
                                Save
                              </Button>
                              <Button
                                disableElevation
                                variant="contained"
                                onClick={() => handleCancel(rowData?.d_id)}
                                name="start"
                                sx={
                                  userRole === "customer"
                                    ? { display: "none" }
                                    : {
                                        width: "60px",
                                        textTransform: "none",
                                        borderRadius: "0",
                                        background: "#db5151",
                                        "&:hover": {
                                          background: "#db5151",
                                        },
                                      }
                                }
                              >
                                Cancel
                              </Button>
                            </Stack>
                          )}
                        </TableCell>

                        {/* ... (other table body cells) */}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack sx={{ display: "flex", alignItems: "flex-end" }}>
            <Pagination
              currentPage={currentPage}
              urlDetails={allDuplicateList}
              setCurrentPage={setCurrentPage}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack
          sx={{
            backgroundColor: "#F8F8F8",
            paddingTop: "10px",
            paddingLeft: { xs: "16px", md: "32px" },
            paddingRight: { xs: "16px", md: "32px" },
            paddingBottom: "32px",
            marginTop: userRole === "customer" ? "-3rem" : "0rem",
          }}
        >
          <TextField
            label="Search by URL ID, URL, S.No or Segment"
            variant="outlined"
            value={searchTerm}
            size="small"
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: "20px", width: "25rem", alignSelf: "flex-end" }}
          />

          <TableContainer
            style={{ maxHeight: "800px" }}
            elevation={0}
            component={Paper}
          >
            <Table sx={{ border: "1px solid #E8E8E8" }}>
              <TableHead
                sx={{
                  color: "#9D9D9D",
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#FFF", // Set background color as needed
                  zIndex: 1,
                }}
              >
                {/* First row for common headings */}
                {/* <TableRow style={{ height: "20px" }}>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  colSpan={6}
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    border: "none",
                    fontWeight: "400",
                    textAlign: "center",
                    borderRadius: "0px 0px 20px 20px",
                    backgroundColor: "rgba(0, 111, 207, 0.06)",
                  }}
                >
                  Dates
                </TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
              </TableRow> */}

                {/* Second row for individual column headings */}
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    URL ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    S. NO
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    URL
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Alternate Link
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    State
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Amex
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Visa
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Master card
                  </TableCell>

                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Discover
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Paypal
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Wallets
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Segment
                  </TableCell>

                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      onClick={toggleAllRows}
                      sx={{
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                    >
                      {allRowsExpanded ? "Collapse All" : "Expand All"}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData?.map((rowData, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      sx={{ fontSize: "16px", fontWeight: "400" }}
                      key={index}
                    >
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {rowData?.url_id}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {rowData?.sno}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                          maxWidth: "150px",
                          overflow: "hidden",
                        }}
                        title={rowData?.url}
                      >
                        {rowData?.url}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                        title={rowData?.alternate_url ?? "N/A"}
                      >
                        {rowData?.alternate_url ?? "N/A"}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {rowData?.last_status_name ?? "N/A"}
                      </TableCell>

                      {/* Card Related rows */}
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {getStatusIcon(
                          rowData?.card_available?.[0]?.includes("amex")
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {getStatusIcon(
                          rowData?.card_available?.[0]?.includes("visa")
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {getStatusIcon(
                          rowData?.card_available?.[0]?.includes("mastercard")
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {getStatusIcon(
                          rowData?.card_available?.[0]?.includes("discover")
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {getStatusIcon(
                          rowData?.card_available?.[0]?.includes("paypal")
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {getStatusIcon(
                          rowData?.card_available?.[0]?.includes("paypal")
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {rowData?.segment ?? "N/A"}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {expandedRows[index] ? (
                          <ArrowDropDownCircleOutlinedIcon
                            onClick={() => toggleRow(index)}
                            style={{
                              cursor: "pointer",
                              transform: "rotate(180deg)",
                            }}
                          />
                        ) : (
                          <ArrowDropDownCircleOutlinedIcon
                            onClick={() => toggleRow(index)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </TableCell>
                      {/* ... (other table body cells) */}
                    </TableRow>

                    {/* Additional row for details with fade-in effect */}
                    {expandedRows[index] && (
                      <TableRow
                        sx={{
                          opacity: 0,
                          animation: "fadeIn 0.3s ease-in forwards", // Apply fadeIn animation
                          "@keyframes fadeIn": {
                            "0%": { opacity: 0 },
                            "100%": { opacity: 1 },
                            backgroundColor: "#E3E3E3",
                          },
                        }}
                      >
                        <TableCell
                          colSpan={12}
                          sx={{ backgroundColor: "#FBFBFB" }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              gap: "8px 10px",
                              flexWrap: "wrap",
                              padding: "0px 14px",
                            }}
                          >
                            {rowData?.url_details?.map((item, index) => (
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                  fontSize: "14px",
                                  lineHeight: "10px",
                                }}
                                key={index}
                              >
                                <p
                                  style={{
                                    padding: "10px",
                                    backgroundColor: "#E3E3E3",
                                  }}
                                >
                                  {item.status_name}
                                </p>
                                <p
                                  style={{
                                    alignSelf: "center",
                                    color: "#5E5E5E",
                                  }}
                                >
                                  {" "}
                                  {item.executor_name}
                                  {","}
                                </p>
                                <p
                                  style={{
                                    alignSelf: "center",
                                    fontWeight: "400",
                                    color: "#5E5E5E",
                                  }}
                                >
                                  {item.date}
                                </p>
                                <p
                                  style={{
                                    alignSelf: "center",
                                    color: "#5E5E5E",
                                    fontWeight: "300",
                                  }}
                                >
                                  {"("}
                                  {item?.duration >= 60
                                    ? Math.floor(item?.duration / 60) +
                                      " " +
                                      "min" +
                                      " " +
                                      (item?.duration % 60) +
                                      " " +
                                      "sec"
                                    : (item?.duration % 60) + " " + "sec"}
                                  {")"}
                                </p>
                                <Divider
                                  sx={{ borderColor: "black" }}
                                  orientation="vertical"
                                />
                              </Stack>
                            ))}
                            <Button
                              style={{
                                alignSelf: "center",
                                textTransform: "none",
                                textDecoration: "underline",
                              }}
                              onClick={() =>
                                handleViewScreenshot(rowData?.image_url)
                              }
                            >
                              View Screenshot
                            </Button>

                            <ImageViewer
                              imageUrl={selectedImageUrl}
                              open={openImageDialog}
                              onClose={handleCloseImageDialog}
                            />

                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box sx={{ ...style, width: 400 }}>
                                <h2 id="parent-modal-title">
                                  No Screenshot available
                                </h2>
                                <Button onClick={handleClose}>Close</Button>
                              </Box>
                            </Modal>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack sx={{ display: "flex", alignItems: "flex-end" }}>
            <Pagination
              currentPage={currentPage}
              urlDetails={filteredData}
              setCurrentPage={setCurrentPage}
            />
          </Stack>
        </Stack>
      )}
    </div>
  );
};
export default TaskDetails;
