import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, makeStyles } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Logo from "../../assests/echnoLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Element, scroller } from "react-scroll";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../slice/LoginSlice";
import { Button, Typography } from "@mui/material";
import { getSelectedCardData } from "../../slice/QASlice";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { getCurrentAssignedURLId } from "../../slice/taskSlice";
import useUpdateAssignTime from "../../hooks/useUpdateAssignTime";

const useStyles = makeStyles(() => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "-webkit-fill-available", // Corrected typo here
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
  },
}));

function Navbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userData= useSelector((state)=>state.auth.userData)
  const handleLogOut = () => {
    dispatch(getSelectedCardData(null));
    dispatch(logout());
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("task_id");
    localStorage.removeItem("user_id");
    navigate("/login");
  };

  const [value, setValue] = useState(() => localStorage.getItem("url_id"));
  const updateAssignTime = useUpdateAssignTime();
  
  useEffect(() => {
    // Function to check and update from localStorage
    const checkAndUpdateValue = () => {
      const currentStorageValue = localStorage.getItem("url_id");
      if (currentStorageValue !== value) {
        setValue(currentStorageValue);
      }
    };
    
    // Run immediately to ensure we're in sync
    checkAndUpdateValue();
    
    // Set up a listener for storage events (changes from other tabs)
    const handleStorageChange = (e) => {
      if (e.key === "url_id") {
        setValue(e.newValue);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    
    // For changes in the same tab, we need to monkey-patch localStorage
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function(key, newValue) {
      // Call the original implementation
      originalSetItem.apply(this, arguments);
      
      // If it's our key of interest, update the state immediately
      if (key === "url_id") {
        setValue(newValue);
      }
    };
    
    // Clean up
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      localStorage.setItem = originalSetItem;
    };
  }, []); // Empty dependency array means this only runs once on mount
  
  // This effect will run whenever value changes
  useEffect(() => {
    if (value !== null && value !== undefined) {
      let param = {
        url_id: value
      }
      updateAssignTime(param)
      console.log(value, "updatedURL");
    }
  }, [value]);

  
  // console.log(value, "Downloading")
  const handleExecutorDashboard = () => {
    if (userData?.role_code === "executor") {
      navigate("/executor-dashboard");
    } else if (userData?.role_code === "admin") {
      navigate("/executor-tab");
    }
  };
//  const changeloaction = () => {
//   navigate("/");
//   // window.location.reload();
//  }
  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Element name="firstContainer">
        <Toolbar className={classes.toolbar}>
          <Box sx={{display:"flex", flexDirection:"row", gap:"1rem"}}>
            <Link
              to="/"
              // onClick={changeloaction}
            >
              <Box component="img" alt="Logo" src={Logo} width="2.5rem" />
            </Link>
            {location.pathname === "/" && <Button
              sx={{
                textTransform: "none",
                borderRadius: "0",
                height:"2.5rem",
                visibility:
                  userData?.role_code === "executor" ||
                  userData?.email === "admin@pops.echnotek.com"
                    ? "visible"
                    : "hidden",
              }}
              disableElevation
              variant="contained"
              onClick={handleExecutorDashboard}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.2rem",
                  fontSize:"14px",
                  
                }}
              >
                Executor Dashboard
                <ArrowForwardOutlinedIcon sx={{ fontSize: "14px" }} />
              </Stack>
            </Button>}
          </Box>

          <Stack
            sx={{
              flexDirection: "row",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>v 1.0.11</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <AccountCircleOutlinedIcon
                fontSize="large"
                sx={{ color: "#006FCF" }}
              />
              <Typography sx={{ fontSize: "large", color: "#4A4A4A" }}>
                {userData?.full_name}
              </Typography>
            </Box>

            {isAuthenticated ? (
              <LogoutIcon
                titleAccess="Logout"
                onClick={handleLogOut}
                fontSize="large"
                sx={{ color: "#006FCF", cursor: "pointer" }}
              />
            ) : (
              <div> </div>
            )}
          </Stack>
        </Toolbar>
      </Element>
    </AppBar>
  );
}

export default Navbar;
