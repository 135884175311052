import { useEffect } from "react";
import { getTaskDetailsList } from "../slice/taskSlice";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
const useTaskDetailsList = (taskStatus, selectedMarket) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllTaskDetailsList = async () => {
    let param={
      task_status: taskStatus,
      country_code: selectedMarket
    }
   
    const response = await Axios.post("/task-details",param);
    const data = await response.data;
    dispatch(getTaskDetailsList(data.taskDetailsList));
  };

  useEffect(() => {
    if(taskStatus && selectedMarket){
      getAllTaskDetailsList();
    }
  }, [taskStatus, selectedMarket]);
  return getAllTaskDetailsList
};

export default useTaskDetailsList;
