import { useEffect } from "react";
import { getTaskDetailsList } from "../slice/taskSlice";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const useMarketDetails = (setIsLoading) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getMarketDetails = async (countryCode) => {
    let param={
      country_code: countryCode
    }
    setIsLoading(true)
    const response = await Axios.post("/get-excel-sheet-market-data",param);
    const data = await response.data;
    if(data.code===200){
      toast.success("Download Successfully!")
      setIsLoading(false)
      return data;
    }else{
      setIsLoading(false)
    }
  };

  return getMarketDetails
};

export default useMarketDetails;
