// TaskUpload.js
import { Imageaxios } from "../../axios/axios";
import useImageAxiosPrivate from "../../utility/useImageAxiosPrivate";

const useTaskUpload = () => {
  const Axios = useImageAxiosPrivate();

  const setTaskUpload = async (param) => {
    try {
        const { data } = await Axios.post('createTask', param);
        return data;
      } catch (error) {
        console.log(error.message);
        return error?.response?.data;
      }
  };
  return setTaskUpload;
};

export default useTaskUpload;




