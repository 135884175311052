import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useMarketDetails from "../../hooks/useMarketDetails";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

// const GenerateTaskExcel = ({ selectedMarket }) => {
//   const getMarketDetails=useMarketDetails()
//   const handleExport = async () => {
//     const workbook = new ExcelJS.Workbook();

//     const taskDetailsList = await getMarketDetails(selectedMarket)
    
//     // Group data by task_type and month_year
//     const firstExecutionTasks = taskDetailsList?.urlDetailsByMarket?.filter(task => task.task_type === "first-execution");
//     const reExecutionTasks = taskDetailsList?.urlDetailsByMarket?.filter(task => task.task_type === "re-execution");
//     const reExecutionGroups = {};

//     reExecutionTasks.forEach(task => {
//       if (task.month_year) {
//         if (!reExecutionGroups[task.month_year]) {
//           reExecutionGroups[task.month_year] = [];
//         }
//         reExecutionGroups[task.month_year].push(task);
//       }
//     });

//     // Create First Execution Sheet
//     if (firstExecutionTasks.length > 0) {
//       const firstSheet = workbook.addWorksheet("TaskDetails");
//       setupWorksheet(firstSheet, firstExecutionTasks);
//     }

//     // Create separate sheets for each month_year in re-execution
//     Object.keys(reExecutionGroups).forEach(monthYear => {
//       const sheet = workbook.addWorksheet(monthYear);
//       setupWorksheet(sheet, reExecutionGroups[monthYear]);
//     });

//     // Generate buffer and save file
//     const buffer = await workbook.xlsx.writeBuffer();
//     const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
//     saveAs(blob, "Task_Details.xlsx");
//   };

//   const setupWorksheet = (worksheet, data) => {
//     // Define columns with headers
//     worksheet.columns = [
//       { header: "URL ID", key: "url_id", width: 15 },
//       { header: "S.No", key: "sno", width: 10 },
//       { header: "URL", key: "url", width: 30 },
//       { header: "Alternate Link", key: "alternate_url", width: 30 },
//       { header: "State", key: "last_status_name", width: 20 },
//       { header: "Segment", key: "segment", width: 15 },
//       { header: "Amex", key: "amex", width: 15 },
//       { header: "Mastercard", key: "mastercard", width: 15 },
//       { header: "Visa", key: "visa", width: 15 },
//       { header: "Discover", key: "discover", width: 15 },
//       { header: "Wallet", key: "wallet", width: 10 },
//       { header: "Paypal", key: "paypal", width: 15 },
//     ];

//     // Apply styles to header row
//     worksheet.getRow(1).eachCell(cell => {
//       cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
//       cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "0070C0" } };
//       cell.alignment = { horizontal: "center" };
//     });

//     // Add rows to worksheet
//     data.forEach(task => {
//       worksheet.addRow({
//         sno: task.sno,
//         url_id: task.url_id,
//         url: task.url,
//         task_type: task.task_type,
//         month_year: task.month_year || "N/A",
//         last_status_name: task.last_status_name,
//         segment: task.segment || "N/A",
//         alternate_url: task.alternate_url || "N/A",
//         image_url: task.image_url || "N/A",
        
//       });
//     });
//   };

//   return (
//     <div>
//       <Button
//         sx={{ textWrap: "nowrap", textTransform: "none", borderRadius: "0.5rem" }}
//         variant="contained"
//         startIcon={<FileDownloadOutlinedIcon />}
//         disableElevation
//         onClick={handleExport}
//       >
//         Download Excel
//       </Button>
//     </div>
//   );
// };

const GenerateTaskExcel = ({ selectedMarket, setIsLoading }) => {
  
  const getMarketDetails=useMarketDetails(setIsLoading)
  const handleExport = async () => {
    if(selectedMarket === "ALL"){
      toast.error("Please Select Market to download!")
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const firstExecutionSheet = workbook.addWorksheet("URL Details");

    const reExecutionSheets = {};

    // Define common columns
    const columns = [
      { header: "S.No", key: "sno", width: 10 },
      { header: "Name", key: "name", width: 15 },
      { header: "Market Code", key: "market_code", width: 15 },
      { header: "URL", key: "url", width: 25 },
      { header: "Alternate URL", key: "alternate_url", width: 20 },
      { header: "URL Where Logo/POP Found", key: "pop_found", width: 15 },
      { header: "Audit Date", key: "audit_date", width: 15 },
      { header: "Type of Page", key: "page_type", width: 15 },
      { header: "Ecommerce Indicator", key: "ecommerce_indicator", width: 20 },
      { header: "Amex", key: "amex", width: 15 },
      { header: "Mastercard", key: "mastercard", width: 15 },
      { header: "Visa", key: "visa", width: 15 },
      { header: "Discover", key: "discover", width: 15 },
      { header: "Wallet", key: "wallet", width: 10 },
      { header: "Paypal (if Wallet Indicator = Y)", key: "paypal", width: 15 },
      { header: "Segment", key: "segment", width: 15 },
      { header: "Comment", key: "remarks", width: 20 },
    ];

    firstExecutionSheet.columns = columns;
    const taskDetailsList = await getMarketDetails(selectedMarket)
    taskDetailsList?.excelSheetMarketData?.forEach((task) => {
      if (task.task_type === "first-execution") {
        firstExecutionSheet.addRow(task);
      } else if (task.task_type === "re-execution" && task.month_year) {
        // Format the month-year to match "RX-MMM(YYYY)"
        const formattedMonthYear = `RX-${task.month_year.split(" ")[0].slice(0, 3).toUpperCase()}(${task.month_year.match(/\d{4}/)[0]})`;

        if (!reExecutionSheets[formattedMonthYear]) {
          reExecutionSheets[formattedMonthYear] = workbook.addWorksheet(formattedMonthYear);
          reExecutionSheets[formattedMonthYear].columns = columns;
        }

        reExecutionSheets[formattedMonthYear].addRow(task);
      }
    });

    // Apply styles to headers
    workbook.eachSheet((worksheet) => {
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "0070C0" } };
        cell.alignment = { horizontal: "center" };
      });
    });

    // Generate buffer and save file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, "URL Details.xlsx");
  };

  return (
    <div>
      <ToastContainer position="bottom-right" autoClose={3000} />
      <Button
        sx={{
          textWrap: "nowrap",
          textTransform: "none",
          borderRadius: "0",
          background: selectedMarket === "ALL" && "gray",
          '&:hover':{
            background: selectedMarket === "ALL" && "gray"
          }
        }}
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
        disableElevation
        onClick={handleExport}
      >
        Download
      </Button>
    </div>
  );
};


export default GenerateTaskExcel;